<template>
  <div class="Chat">
    <v-row style="margin: 0;">
      <v-col class="Chat__mainContainer">
        <div class="toolBar">
          <div class="Chat__settings">
            <v-btn icon @click.stop="isSettingsOpen=true">
              <v-icon> mdi-settings </v-icon>
            </v-btn>
            <v-btn v-if="currentSession?.id" icon @click.stop="setEditSession(currentSession); isMemoryEditOpen = true">
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </div>
          <div class="Chat__return">
            <v-btn icon @click.stop="goToStartPage" title="Return to start page">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <div>
              <v-btn icon @click.stop="selectSession(null)" title="Start New Session">
                <v-icon> mdi-plus</v-icon>
              </v-btn>
              <span>Start New Session</span>
            </div>
          </div>
          <div class="Chat__sessionContainer">
            <div class="Chat__sessionHeader">
                <span class="Chat__text" style="font-weight: bold; padding-left: 10px">
                  <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small>
                      <v-icon> mdi-account-group-outline </v-icon>
                    </v-btn>
                  </template>
                  <v-list style="overflow: auto; height: 300px">
                    <span style="padding: 15px; font-weight: bold">Agents:</span>
                     <v-list-item
                         @click="changeWorkspace(null)"
                     >
                        <v-list-item-title><span style="color: #40acf6">None</span></v-list-item-title>
                     </v-list-item>
                     <v-list-item
                         v-for="(item, i) in workspaces"
                         :key="i"
                         @click="changeWorkspace(item)"
                     >
                       <v-list-item-title>{{ item.name }}</v-list-item-title>
                     </v-list-item>
                  </v-list>
                  </v-menu>
                  <span style="font-weight: bold" v-if="this.currentSession?.workspace?.name || this.chatWorkspace?.name" class="workspaceLink" @click.stop="goToWorkspace(currentSession?.workspace || chatWorkspace)">
                      {{ currentSession?.workspace?.name || chatWorkspace?.name }}
                  </span>
                  <span v-if="this.currentSession?.workspace?.name || this.chatWorkspace?.name">/</span>
                  <span v-on:dblclick="() => { if(!currentSession?.name) { return } setEditSession(currentSession); isEditSessionOpen = true }">  {{ this.currentSession?.name || 'New Session' }}</span>
                                    <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small>
                      <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list style="overflow: auto; height: 300px">
                    <span style="padding: 15px; font-weight: bold">Recent Chats:</span>
                     <v-list-item
                         @click="selectSession(null)"
                     >
                        <v-list-item-title><span style="color: #40acf6">Start New</span></v-list-item-title>
                     </v-list-item>
                     <v-list-item
                         v-for="(item, i) in sessionsList"
                         :key="i"
                         @click="selectSession(item)"
                     >
                       <v-list-item-title>{{ item.name }}</v-list-item-title>
                     </v-list-item>
                    <v-list-item>
                      <v-btn class="textButton" @click.stop="loadMoreSessions()" :loading="sessionsListLoading" v-if="canLoadMoreSession">
                        Load more
                      </v-btn>
                     </v-list-item>
                  </v-list>
                  </v-menu>
                </span>
            </div>
          </div>
        </div>
        <v-row class="Chat__chat" id="chat_container">
          <v-col v-if="!currentSessionLoading" ref="chat" id="chat" class="pl-1 pt-5">
            <div v-for="item in currentSession?.Messages || []" :key="item.id" class="Chat__message">
              <v-row class="Chat__messageContentContainer"
                     v-if="!(userSettings?.hideToolIO && item.type ==='tool_result')">
                <v-col v-if="item.role ==='assistant'" class="Chat__messageIcon">
                  <img
                      :src="`${publicPath}/icons/ai_response.svg`"
                      alt=""
                      height="20"
                      style="vertical-align: middle"
                  />
                </v-col>
                <v-col class="Chat_messageContent">
                  <v-row v-for="(content, index) in item.content || []" :key="item.id+index" class="Chat__message">
                    <v-col>
                      <v-row
                          v-if="item.role === 'assistant' && !(userSettings?.hideToolIO && content.type ==='tool_use')"
                          :class="'Chat__messageBot'" align-content="end">
                        <div :class="botMessageStyleMapping[content.type]">
                          <span style="white-space: pre-line" v-if="content.type ==='text'">{{ content.text?.replaceAll(paperPattern, 'DOCUMENT') }}</span>
                          <span style="white-space: pre-line" v-if="content.type ==='thinking'">{{ content.thinking }}</span>
                          <v-row v-if="content.type ==='tool_use'" style="display: flex">
                            <v-col align-content="center" style="align-self: center; display: flex; max-width: 120px">
                              <span style="font-weight: bold">Action Input: </span>
                            </v-col>
                            <json-viewer :value="content.input"
                                         style="white-space: pre-line; background: transparent"></json-viewer>
                          </v-row>
                        </div>

                        <div class="Chat__messageToolbar" v-if="content.type ==='text'">
                          <v-btn class="copy_btn" icon @click.stop="copy(content.text)"
                                 v-if="item?.role === 'assistant'">
                            <v-icon small> mdi-content-copy</v-icon>
                          </v-btn>
                        </div>
                      </v-row>

                      <div
                          v-if="item.role === 'user' && item.type ==='tool_result' && JSON.stringify(item?.content)?.includes('Error: Maximum number of tool calls reached')"
                          style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background: white;
                              color: black;
                              border-radius: 25px;
                              border: 1px solid #f4f4f4;
                              margin: 25px 50px 25px 50px;
                              padding: 5px;
                          "
                      >
                        <span style="font-weight: bold">Maximum number of action calls reached</span>
                      </div>

                      <v-row v-if="item.role === 'user' && !(userSettings?.hideToolIO && content.type ==='tool_result') && !JSON.stringify(item?.content)?.includes('Error: Maximum number of tool calls reached')"
                             :class="'Chat__messageUser'"
                             :align-content="content.type ==='tool_result' ? 'center' : 'end'">
                        <div :class="userMessageStyleMapping[content.type]">
                          <span style="white-space: pre-line"
                                v-if="content.type ==='text' && !content.text.includes('<paper>')">{{ content.text }}</span>
                          <span v-if="content.type ==='text' && content.text.includes('<paper>')">Document:</span>
                          <div style="max-height: 70px; overflow: auto; text-overflow: ellipsis;"
                               v-if="content.type ==='text' && content.text.includes('<paper>')">
                            {{ content.text }}
                          </div>
                          <span style="white-space: pre-line" v-if="content.type ==='image'"><v-img :width="600"
                                                                                                    style="border-radius: 15px;"
                                                                                                    :src="'data:image/png;base64,'+content?.source?.data"></v-img></span>
                          <v-row v-if="content.type ==='tool_result' && !JSON.stringify(content?.content)?.includes('Error: Maximum number of tool calls reached')">
                            <v-col align-content="center" style="align-self: center">
                              <span style="font-weight: bold">Action Result: </span>
                            </v-col>
                            <json-viewer
                                :value="typeof content.content === 'string' && isJSONString(content.content) ? JSON.parse(content.content) : content.content"
                                style="white-space: pre-line; background: transparent"></json-viewer>
                          </v-row>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="item.role ==='user' && !JSON.stringify(item?.content)?.includes('Error: Maximum number of tool calls reached')" class="Chat__messageIcon">
                  <component :is="`style`">
                    #avatar_{{ item.User.email.split('@')[0] }} {background:
                    {{ stringToColour(item.User.email.split('@')[0]) }};}
                  </component>
                  <div :id="`avatar_${item.User.email.split('@')[0]}`" class="Chat__userAvatar">
                    {{ item.User.email.slice(0, 1).toUpperCase() }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="Chat__message" v-if="isToolExecuting">
              <v-row class="Chat__messageContentContainer">
                <v-col class="Chat_messageContent">
                  <v-row class="Chat__message">
                    <v-col>
                      <v-row
                          :class="'Chat__messageUser'"
                          :align-content="'end'">
                        <div :class="'Chat__toolResultMessage'">
                          <span style="white-space: pre-line; font-weight: bold">Action is Executing    <v-progress-circular
                              :size="20"
                              :width="1"
                              color="primary"
                              indeterminate
                          /></span>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div class="Chat__message" v-if="sendMessageLoading">
              <vue-loaders-ball-beat color="gray" scale="0.5"/>
            </div>
          </v-col>
          <loader v-if="currentSessionLoading"></loader>
        </v-row>
        <div class="Chat__buttonSection">
          <v-row class="Chat__messageInput">
            <v-col>
              <v-textarea
                  no-resize
                  class="TextArea"
                  outlined
                  dense
                  :placeholder="'Message'"
                  hide-details="auto"
                  novalidate
                  :rows="3"
                  v-model="message"
                  id="message_input"
                  @keydown.enter.prevent=""
              >
              </v-textarea>
              <v-row class="Chat__files" v-if="filesAccumulated.length !== 0">
                <div class="filesContainer">
                  <div v-for="(file, index) in filesAccumulated" :key="index" class="fileItem">
                    <div style="position:relative; display: flex; justify-content: start">
                      <button @click.stop="onFileDelete(index)" class="closeButton">
                        <span>&times;</span>
                      </button>
                      <img v-if="file.type.includes('image')" :src="createObjectURL(file)" class="image"/>
                      <div style="height: 80px">
                        <img v-if="!file.type.includes('image')" class="document"
                             :src="`${publicPath}/icons/file-icon.svg`"/>
                      </div>
                      <span style="top: 0px">
                   {{ file.name }}
                 </span>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-col>
            <v-col style="max-width: 3%">
              <v-row v-if="!sendMessageLoading && !isToolExecuting">
                <v-btn icon @click.stop="send()" class="Chat__send_button">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </v-row>
              <v-row v-if="sendMessageLoading || isToolExecuting">
                <v-btn icon @click.stop="stopSession()" class="Chat__send_button">
                  <v-icon> mdi-pause </v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-file-input
                    label="File input"
                    hide-input
                    v-model="files"
                    class="Chat__attach_button"
                    multiple
                    accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
                    @change="onFileChange"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <a-i-chat-settings :dialog="isSettingsOpen" @close="isSettingsOpen = false"/>
    <edit-session-name :dialog="isEditSessionOpen" @close="isEditSessionOpen = false"/>
  </div>
</template>

<script>
import moment from 'moment';
import {DynamicScroller, DynamicScrollerItem} from 'vue-virtual-scroller'
import Vue from 'vue'
import {createNamespacedHelpers} from "vuex";

const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');
const {mapGetters: customerGetters} =
    createNamespacedHelpers('customer');
const {mapGetters: workspacesGetters, mapActions: workspaceActions} = createNamespacedHelpers('aiWorkspace');

import VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import AIChatSettings from "@/views/ai-chat/ai-chat-settings/ai-chat-settings.vue";
import {io} from 'socket.io-client';

import JsonViewer from "vue-json-viewer";
import Cookies from "js-cookie";
import EditSessionName from "@/views/ai-chat/edit-modal/edit-session-modal.vue";
import constants from "@/util/constants";
import PromptTab from "@/views/promts/tabs/prompt-tab.vue";
import SystemPromptTab from "@/views/promts/tabs/system-prompts-tab.vue";
import ToolsTab from "@/views/promts/tabs/tools-tab.vue";
import Loader from "@/components/loader/loader.vue";
import cloneDeep from "lodash/cloneDeep";

Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.use(VueLoaders);

export default {
  name: 'AIChat',
  components: {Loader, ToolsTab, SystemPromptTab, PromptTab, EditSessionName, AIChatSettings, JsonViewer},
  data() {
    return {
      memoryModalKey: 'memory_key',
      AI_CHAT_WEBSOCKET_URL: 'https://dev.shifthealth.io/ws/ai_service',
      IS_LOCAL_HOST: 'false',
      publicPath: constants.isDevelopment ? "" : "",
      editSession: null,
      isEditSessionOpen: false,
      isMemoryEditOpen: false,
      socket: undefined,
      paperPattern: /<paper>(.*?)<\/paper>/gs,
      files: [],
      filesAccumulated: [],
      sessionPage: 0,
      messageCheckingTimer: null,
      message: '',
      isSettingsOpen: false,
      canLoadMoreSession: true,
      userMessageStyleMapping: {
        text: 'Chat__messageContentUser',
        image: 'Chat__imageMessage',
        tool_result: 'Chat__toolResultMessage',
      },
      botMessageStyleMapping: {
        text: 'Chat__messageContentBot',
        thinking: 'Chat__thinkingContent',
        tool_use: 'Chat__toolUseMessage'
      },
    }
  },
  watch: {
    async 'selectedCustomer.customer_id'() {
      this.getSessions({page: this.sessionPage, push: false})
      this.setSession(null);
      this.setChatWorkspace(null)
      this.$router.push({query: {}});
    },
  },
  computed: {
    ...AIChatGetters({
      sessionsList: 'SESSIONS_LIST',
      sessionsListLoading: 'SESSIONS_LIST_LOADING',
      toolSets: 'TOOL_SETS',
      toolSetsLoading: 'TOOL_SETS_LOADING',
      createToolSetLoading: 'CREATE_TOOL_SET_LOADING',
      currentToolSet: 'CURRENT_TOOL_SETS',
      currentToolSetLoading: 'CURRENT_TOOL_SET_LOADING',
      AIResponseLoading: 'AI_RESPONSE_LOADING',
      currentSession: 'CURRENT_SESSION',
      currentSessionLoading: 'CURRENT_SESSION_LOADING',
      userSettings: 'USER_SETTINGS',
      userSettingsLoading: 'USER_SETTINGS_LOADING',
      sendMessageLoading: 'SEND_MESSAGE_LOADING',
      sendMessageData: 'GET_SEND_MESSAGE_DATA',
      chatWorkspace: 'GET_CHAT_WORKSPACE',
    }),
    ...customerGetters({
      user: 'USER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    ...workspacesGetters({
      workspaces: 'WORKSPACES_LIST',
      workspacesLoading: 'WORKSPACE_LIST_LOADING',
    }),
    isToolExecuting() {
      if(!this.currentSession?.Messages) {
        return false;
      }
      const lastMessage = this.currentSession.Messages[this.currentSession.Messages.length - 1];
      return lastMessage.type === 'tool_user';
    },
  },
  methods: {
    cloneDeep,
    ...AIChatActions(['createToolSet', 'getCurrentSession', 'getSessions', 'getToolSets', 'getUserSettings', 'sendMessage', 'getToolSets', 'updateUserSettings', 'getLastMessage', 'updateSession', 'hardStopSession']),
    ...workspaceActions(['getWorkspaces', 'getWorkspace']),
    ...AIChatMutations({
      setSession: 'SET_CURRENT_SESSION',
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setEditSession: 'SET_EDIT_SESSION',
      setSendMessage: 'SET_SEND_MESSAGE',
      setChatWorkspace: 'SET_CHAT_WORKSPACE',
    }),
    getMemory() {
      if(typeof this.currentSession?.memory === 'string') {
        return JSON.parse(cloneDeep(this.currentSession?.memory));
      }
      return cloneDeep(this.currentSession?.memory) || {};
    },
    createObjectURL(file) {
      return URL.createObjectURL(file)
    },
    async updateCurrentSession(data) {
      await this.updateSession({ ...data, sessionId: this.currentSession?.id })
      this.memoryModalKey = 'memory_key' + Math.random();
    },
    async changeWorkspace(workspace) {
      this.setSendMessageLoading(false);
      this.setChatWorkspace(workspace);
      this.setSession(null);
    },
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    stringToColour(str) {
      let hash = 0;
      str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
      })
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
      }
      return colour
    },
    async copy(text) {
      await navigator.clipboard.writeText(text);
    },
    isJSONString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    toggleScroll(enable) {
      if (enable) {
        document.getElementById('chat_container').style.overflow = 'auto'
      }
      if (!enable) {
        document.getElementById('chat_container').style.overflow = 'hidden'
      }
    },
    async loadMoreSessions() {
      this.sessionPage += 1;
      const preLoadCount = this.sessionsList.length;
      await this.getSessions({page: this.sessionPage, push: true})
      const postLoad = this.sessionsList.length;
      if (postLoad === preLoadCount) {
        this.canLoadMoreSession = false;
      }
    },
    async selectSession(session) {
      if (this.currentSession?.id === session?.id) {
        return
      }
      this.setSendMessageLoading(false);

      if (!session) {
        this.setSession(null);
        return;
      }
      await this.getCurrentSession({sessionId: session?.id});
      this.$router.push({query: {session: this.currentSession.id}});
      this.scroll();
    },
    makeDisplayMessage(files) {
      const init = {
        id: Math.random(),
        content: [
          {
            text: this.message,
            type: 'text'
          }
        ],
        role: 'user',
        type: 'common',
        User: {
          email: this?.user?.email
        }
      }
      for (const file of files) {
        if (file.type.includes('image/')) {
          init.content.push({
            type: 'image',
            source: {
              "type": "base64",
              data: file.base64.replace(/^data:image\/\w+;base64,/, ''),
            }
          })
        } else {
          init.content.push({
            text: `${file.name}`,
            type: 'text',
          });
        }
      }
      return init;
    },
    async stopSession() {
      await this.hardStopSession({ sessionId: this.currentSession.id });
    },
    async send(memory, fromAnotherPage = false) {
      if((this.sendMessageLoading && !fromAnotherPage) || this.isToolExecuting) {
        return;
      }
      if (this.message === '') {
        return;
      }
      const files = await this.prepareFiles();
      const displayMessage = this.makeDisplayMessage(files)
      if (!this.currentSession.id) {
        this.setSession({
          Messages: [displayMessage]
        })
      } else {
        this.currentSession.Messages.push(displayMessage)
      }

      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);
      this.toggleScroll(false)


      this.filesAccumulated = [];
      this.files = [];

      this.scroll();

      const {session, answer} = await this.sendMessage({
        sessionId: this.currentSession.id,
        workspaceId: this.currentSession?.workspaceId || this.sendMessageData?.workspaceId || this?.chatWorkspace?.id,
        files: files,
        content: [{
          text: messageValue,
          type: 'text',
        }],
        memory: memory,
      });

      if (!this.currentSession?.id) {
        const sessionList = this.sessionsList;
        sessionList.pop();
        sessionList.unshift(session);
        this.sessionsList = sessionList;
        this.$router.push({query: {session: session.id}});
      }

      if (answer.type === 'error') {
        this.setSendMessageLoading(false);
      }

      if (!this.currentSession.id) {
        this.setSession(session);
      } else {
        const sessionCopy = this.currentSession;
        const lastIndex = session.Message.length - 1;
        sessionCopy.Messages[lastIndex] = answer;
        this.setSession(sessionCopy)
      }
      this.scroll();
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format(`HH:MM:SS, DD MMMM YYYY`);
    },
    onFileChange(event) {
      event.forEach(thisFile => {
        if (this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError', `${thisFile.name} too big`)
          return;
        }
        this.filesAccumulated.push(thisFile)
      })
      this.files = [];
    },
    scroll() {
      setTimeout(() => {
        document.querySelectorAll(".copy_btn")[document.querySelectorAll(".copy_btn").length - 1].scrollIntoView({
          behavior: 'instant',
          block: 'start',
          inline: 'start'
        })
      }, 10)
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async prepareFiles() {
      const prepared = await Promise.all(this.filesAccumulated.map(async (file) => {
        const base64 = await this.toBase64(file)
        return {
          base64: base64,
          name: file.name,
          type: file.type
        }
      }));
      return prepared;
    },
    connectSocket() {
      const path = this.IS_LOCAL_HOST === 'true' ? {} : {path: '/ws/ai_service/socket.io'}
      this.socket = io(this.AI_CHAT_WEBSOCKET_URL, {
        auth: {
          userId: Cookies.get('userId'),
        },
        ...path
      });

      this.socket.on('chat:message_chunk', this.processMessageChunk);
    },
    goToWorkspace(item) {
      this.$router.push({ path: `/agents/${item.id}` });
    },
    goToStartPage() {
      this.$router.push({ path: `/start-chat` });
    },
    processMessageChunk({chunk_type, chunk, message, messageType}) {
      const lastIndex = this.currentSession.Messages.length - 1;
      if (chunk_type === 'content_block_stop') {
        this.toggleScroll(true);
        this.setSendMessageLoading(false);
      }
      if (chunk_type === 'new_message') {
        this.toggleScroll(false)
        const newMessage = message ? message : {
          id: Math.random(),
          content: [],
          role: 'assistant',
          type: 'common',
        }
        if (newMessage.type === 'error' || JSON.stringify(message?.content)?.includes("Error: Maximum number of tool calls reached")) {
          this.setSendMessageLoading(false)
        } else {
          this.setSendMessageLoading(true)
        }
        this.currentSession.Messages.push(newMessage);
        return;
      }
      if (chunk_type === 'content_block_start') {
        let message = chunk.content_block
        if (message.type === 'tool_use') {
          message.input = ''
        }
        this.currentSession.Messages[lastIndex].content.push(message);
      }

      if (chunk_type === 'content_block_delta') {
        if (chunk.delta.type === 'input_json_delta') {
          this.currentSession.Messages[lastIndex].content[chunk.index].input += typeof chunk.delta.partial_json === 'object' ? JSON.stringify(chunk.delta.partial_json) : chunk.delta.partial_json
        }
        if (chunk.delta.type === 'text_delta') {
          this.currentSession.Messages[lastIndex].content[chunk.index].text += chunk.delta.text;
        }
        if (chunk.delta.type === 'thinking_delta') {
          this.currentSession.Messages[lastIndex].content[chunk.index].thinking += chunk.delta.thinking;
        }
      }

      this.currentSession.Messages[lastIndex].type = messageType;

      this.scroll();
    }
  },
  async created() {
    this.AI_CHAT_WEBSOCKET_URL = process.env.VUE_APP_AI_CHAT_WEBSOCKET_URL;
    this.IS_LOCAL_HOST = process.env.VUE_APP_AI_CHAT_IS_LOCALHOST;
    this.connectSocket()
    this.setSession(null);

    await this.getUserSettings();

    this.getSessions({page: this.sessionPage, push: false})
    this.getWorkspaces();

    if (this.$route.query.session) {
      await this.getCurrentSession({sessionId: this.$route.query.session});
    }

    this.scroll();

    if (this.sendMessageData) {
      this.filesAccumulated = this.sendMessageData.files;
      this.message = this.sendMessageData.messageValue;
      await this.send(this.sendMessageData.memory, true);
      this.setSendMessage(null);
    }

    const lastIndex = (this.currentSession?.Messages?.length || 1) - 1;
    let messageType;
    try {
      messageType = this.currentSession?.Messages[lastIndex]?.type
    } catch (e) {
      console.log(e)
    }
    if (messageType === 'error' || !messageType) {
      this.setSendMessageLoading(false)
      this.toggleScroll(true);
    }
  },
  beforeDestroy() {
    this.socket?.disconnect();
  },
  mounted() {
    document.getElementById('message_input').addEventListener("keydown", async (e) => {
      const getCaret = (el) => {
        if (el.selectionStart) {
          return el.selectionStart;
        } else if (document.selection) {
          el.focus();
          const r = document.selection.createRange();
          if (r == null) {
            return 0;
          }
          const re = el.createTextRange(), rc = re.duplicate();
          re.moveToBookmark(r.getBookmark());
          rc.setEndPoint('EndToStart', re);
          return rc.text.length;
        }
        return 0;
      }

      const start = e.target.selectionStart
      const end = e.target.selectionEnd
      const caret = getCaret(e.target);
      if (e.key == "Enter" && e.ctrlKey == false && e.shiftKey == false && this.message.replaceAll('\n', '') !== '') {
        await this.send()
      }
      if (e.key == "Enter" && e.ctrlKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
      if (e.key == "Enter" && e.shiftKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
    })
    document.getElementById('message_input').onpaste = (event) => {
      const items = (event?.clipboardData || event?.originalEvent?.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob)
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import './ai-chat';
</style>
